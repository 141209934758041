<template>
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.0003 18.483C39.9108 17.2628 39.702 16.0427 39.4037 14.8522L29.709 17.4117C29.8581 18.0069 29.9775 18.6318 30.0073 19.227V19.2568L34.9889 18.9294L39.9704 18.6021L40.0003 18.483Z"
      fill="#FC4236"
    />
    <path
      d="M29.6191 22.9174L39.1945 25.8637C39.5525 24.6732 39.8209 23.4828 39.9403 22.2626L29.9771 21.1317C29.9174 21.7269 29.7981 22.3519 29.6191 22.9174Z"
      fill="#FC4236"
    />
    <path
      d="M37.7633 29.2861L28.9039 24.6435C28.6056 25.1792 28.2774 25.7148 27.8896 26.191L35.7349 32.3812C36.5105 31.3991 37.1966 30.3575 37.7633 29.2861Z"
      fill="#FC4236"
    />
    <path
      d="M28.1279 14.1082C28.4859 14.6142 28.814 15.1498 29.0825 15.6855L38.1209 11.4C37.584 10.2989 36.9576 9.22747 36.2416 8.21561L28.1279 14.1082Z"
      fill="#FC4236"
    />
    <path
      d="M26.4275 27.6791C24.8764 28.9588 22.9374 29.7921 20.7897 29.9409C15.2712 30.3575 10.4686 26.2208 10.0509 20.7151C9.63333 15.2094 13.7797 10.4179 19.2982 10.0012C19.5667 9.97148 19.8053 9.97148 20.0738 9.97148C22.4602 9.97148 24.7571 10.8048 26.6065 12.3821C26.6662 12.4416 26.7258 12.4714 26.7855 12.5309C26.8451 12.5904 26.9048 12.6499 26.9645 12.6797L33.8253 5.4181C33.6762 5.29906 33.527 5.15025 33.4077 5.03121C33.3182 4.94193 33.2287 4.88241 33.1392 4.79313C29.0526 1.37065 23.8622 -0.355471 18.5226 0.061178C7.51541 0.894477 -0.777286 10.5072 0.0579496 21.4889C0.445737 26.816 2.92161 31.667 6.97847 35.149C10.6475 38.3036 15.2115 40 20.0141 40C20.5212 40 21.0283 39.9702 21.5354 39.9405C25.8309 39.6131 29.7088 37.9465 32.7813 35.4169C32.9304 35.2978 33.0796 35.1788 33.1989 35.0597L26.6363 27.5303C26.5468 27.5898 26.4872 27.6196 26.4275 27.6791Z"
      fill="currentColor"
    />
  </svg>
</template>
