import { v4 as uuidv4 } from 'uuid'
import type { SelectedVehicleType } from '@/pages/request-quote/index.vue'

export const useVehicleRequestStorage = () => {
  const vehicleCache = useState<Map<string, SelectedVehicleType> | null>(
    'cart',
    () => null
  )

  const cartId = useCookie('cart-id', {
    maxAge: 60 * 60 * 25, // extra hour added
    secure: true,
    sameSite: 'strict',
  })

  const storeSelectedVehicles = async (
    selectedVehicles: Map<string, SelectedVehicleType>
  ) => {
    if (!selectedVehicles.size) return

    if (!cartId.value) {
      const newCartId = uuidv4()
      cartId.value = newCartId
    }

    vehicleCache.value = selectedVehicles

    try {
      await $fetch('/api/shopping-cart', {
        method: 'POST',
        body: JSON.stringify([...selectedVehicles]),
        query: {
          cartId: cartId.value,
        },
      })
    } catch (error) {
      console.error('Error storing shopping cart', error)
    }
  }

  const fetchStoredVehicles = async (): Promise<
    Map<string, SelectedVehicleType>
  > => {
    if (vehicleCache.value) return vehicleCache.value

    if (!cartId.value) return new Map()

    try {
      const response = await $fetch('/api/shopping-cart', {
        query: {
          cartId: cartId.value,
        },
      })

      return new Map(response)
    } catch (error) {
      console.error('Error fetching shopping cart', error)
      return new Map()
    }
  }

  const removeStoredVehicles = async () => {
    try {
      await $fetch('/api/shopping-cart', {
        method: 'POST',
        body: null,
        query: {
          cartId: cartId.value,
        },
      })
    } catch (error) {
      console.error('Error removing shopping cart', error)
    } finally {
      vehicleCache.value = null
      cartId.value = null
    }
  }

  return {
    storeSelectedVehicles,
    fetchStoredVehicles,
    removeStoredVehicles,
  }
}
